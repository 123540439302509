<script>
    import { onMount } from 'svelte';
    import { router } from "tinro";
    import { get, set } from 'idb-keyval';
    import { configStore } from '../stores/configStore';

    let readding_backend_token;

    function removeTrailingSlash(str) {
        return str.endsWith("/") ? str.slice(0, -1) : str;
    }

    async function doSave() {
        if (!readding_backend_token) {
            alert("Server and Token are required fields.");
            return;
        }

        await set('readding_backend_token', readding_backend_token);

        configStore.notifyConfigChanged();

        console.log("Saved settings to the idb-keyval database");
        router.goto("/home");
    }

    onMount(async () => {
        readding_backend_token = await get('readding_backend_token') || '';
    });
</script>

<main>
    <div class="container">
        <div class="columns">
            <div class="column col-12">
                <form class="form-horizontal">
                    <div class="form-group">
                        <label class="form-label" for="readding-backend-token"
                            >Readding Backend Token</label
                        >
                        <input
                            class="form-input"
                            type="text"
                            id="readding-backend-token"
                            placeholder="Reading backend token"
                            bind:value={readding_backend_token}
                        />
                    </div>

                    <div class="form-group">
                        <button
                            class="btn btn-primary"
                            type="button"
                            on:click={doSave}
                        >
                            Save Settings
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>

<style>
</style>
