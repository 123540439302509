<script>
  export let bookmark;
  
  
  function share() {
          if (navigator.share) {
              navigator
                  .share({
                      title: bookmark.title,
                      text: bookmark.description,
                      url: bookmark.url,
                  })
                  .then(() => console.log("Successful share"))
                  .catch((error) => console.log("Error sharing", error));
          }
      }
</script>

<style>
  .btn-link {
    color: #bcc3ce;
    padding: 0;
    height: auto;
    vertical-align: unset;
    border: none;
  }
</style>

{#if navigator.share}
 <button class="btn btn-link btn-sm confirm" on:click={share}>Share</button>
 <span class="text-gray text-sm">|</span>
{/if} 