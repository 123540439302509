<script>
    import YouTubePlayer from "./YouTubePlayer.svelte";
    import { Pulse } from "svelte-svg-spinners";
    import { configStore } from '../stores/configStore';
    
    export let youTubeId;

    let transcript = [];
    let loading = true;
    let readding_backend_token;

    configStore.subscribe(config => {
        readding_backend_token = config.readding_backend_token;
    });

    async function getTranscript(id) {
        const res = await fetch(
            "https://readding.eisenacher81.org/api/apps/youtube/" + id,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${readding_backend_token}`,
                },
            },
        );
        return res.json();
    }

    console.log("Displaying youtube video ", youTubeId);

    $: if (readding_backend_token) {
        getTranscript(youTubeId).then((data) => {
            transcript = data;
            loading = false;
        });
    }

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
</script>

<div class="youtube-page">
    <YouTubePlayer videoId={youTubeId} />
</div>

{#if loading}
    <div class="flex-centered">
        <Pulse />
    </div>
{:else}
    <div class="transcript_title">Transcript</div>
    <div class="transcript-container">
        {#each transcript as part}
            <div class="transcript-part">
                <span class="timestamp">{formatTime(part.start)}</span>
                <span class="text">{part.text}</span>
            </div>
        {/each}
    </div>
{/if}

<style>
    .youtube-page {
        width: 100%;
        max-width: 640px;
        margin: 0 auto;
    }
    :global(.video-js .vjs-big-play-button) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .transcript_title {
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 1rem;
    }
    .transcript-container {
        max-height: 400px;
        overflow-y: auto;
        border: 1px solid #ccc;
        padding: 10px;
        margin-top: 10px;
    }
    .transcript-part {
        margin-bottom: 10px;
    }
    .timestamp {
        font-weight: bold;
        margin-right: 10px;
        color: #666;
    }
    .text {
        white-space: pre-wrap;
    }
</style>
