<script>
  import { onMount } from 'svelte';

  export let videoId = '';

  let player;
  let videoElement;

  onMount(() => {
    if (typeof videojs !== 'undefined') {
      let src = `https://www.youtube.com/watch?v=${videoId}`
      console.log("Playing youtube video : ", src)
      player = videojs(videoElement, {
        techOrder: ["youtube"],
        sources: [{ type: "video/youtube", src: src }],
        youtube: { iv_load_policy: 3 },
        controls: true,
        autoplay: false,
        preload: 'auto',
        width: 640,
        height: 360
      });

      return () => {
        if (player) {
          player.dispose();
        }
      };
    } else {
      console.error('Video.js is not loaded');
    }
  });
</script>

<div class="video-container">
  <video-js bind:this={videoElement} class="video-js vjs-big-play-centered vjs-16-9">
    <source src="https://www.youtube.com/watch?v={videoId}" type="video/youtube">
  </video-js>
</div>

<style>
  .video-container {
      padding-top: 20px;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
  }
  :global(.video-js) {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
</style>

