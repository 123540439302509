import { writable } from 'svelte/store';

function createBookmarksStore() {
    // Read from localStorage on initialization
    const storedBookmarks = localStorage.getItem('bookmarks');
    const initialBookmarks = storedBookmarks ? JSON.parse(storedBookmarks) : [];

    // Create a writable store
    const { subscribe, set, update } = writable(initialBookmarks);

    return {
        subscribe,
        set: (bookmarks) => {
            localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
            set(bookmarks);
        },
        update: (updater) => {
            update(bookmarks => {
                const updatedBookmarks = updater(bookmarks);
                localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
                return updatedBookmarks;
            });
        },
        addBookmark: (bookmark) => {
            update(bookmarks => {
                const updatedBookmarks = [...bookmarks, bookmark];
                localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
                return updatedBookmarks;
            });
        },
        removeBookmark: (id) => {
            update(bookmarks => {
                const updatedBookmarks = bookmarks.filter(b => b.id !== id);
                localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
                return updatedBookmarks;
            });
        }
    };
}

export const bookmarks = createBookmarksStore();