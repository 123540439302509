<script>
    import { Route, meta, router } from "tinro";
    import Home from "./routes/home.svelte";
    import Settings from "./routes/settings.svelte";
    import Share from "./routes/share.svelte";
    import Article from "./routes/article.svelte";
    import { onMount } from "svelte";
    import { configStore } from "./stores/configStore";

    let isMenuOpen = false;
    let lastKeyPressed = "";
    let keyPressTimeout;
    let isConfigInitialized = false;

    function handleConnection() {
        if (navigator.onLine) {
            isReachable("https://readding.eisenacher81.org/api/health").then(
                function (online) {
                    if (online) {
                        // handle online status
                        console.log("online");
                        router.goto("/");
                    } else {
                        console.log("no connectivity");
                    }
                },
            );
        } else {
            // handle offline status
            console.log("offline");
        }
    }

    function isReachable(url) {
        return fetch(url, { method: "HEAD", mode: "no-cors" })
            .then(function (resp) {
                return resp && (resp.ok || resp.type === "opaque");
            })
            .catch(function (err) {
                console.warn("[conn test failure]:", err);
            });
    }

    onMount(async () => {
        window.addEventListener("online", handleConnection);
        window.addEventListener("offline", handleConnection);

        window.addEventListener("keydown", handleKeydown);

        try {
            await configStore.initConfig();
            isConfigInitialized = true;

            const unsubscribe = configStore.subscribe((config) => {});

            return () => {
                window.removeEventListener("keydown", handleKeydown);
                unsubscribe();
            };
        } catch (error) {
            console.error("Error initializing config:", error);
            if (window.location.pathname !== "/settings") {
                router.goto("/settings");
            }
        }
    });

    function handleKeydown(event) {
        if (event.key === "g") {
            lastKeyPressed = "g";
            clearTimeout(keyPressTimeout);
            keyPressTimeout = setTimeout(() => {
                lastKeyPressed = "";
            }, 1000);
        } else if (lastKeyPressed === "g") {
            event.preventDefault();
            switch (event.key) {
                case "h":
                    router.goto("/home");
                    break;
                case "c":
                    router.goto("/share");
                    break;
                case "s":
                    router.goto("/settings");
                    break;
            }
            lastKeyPressed = "";
        } else {
            lastKeyPressed = "";
        }
    }

    function toggleMenu() {
        isMenuOpen = !isMenuOpen;
        console.log("Menu toggled, isMenuOpen:", isMenuOpen);
        if (isMenuOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }

    function closeMenu() {
        isMenuOpen = false;
        //console.log("Menu closed, isMenuOpen:", isMenuOpen);
        document.body.style.overflow = "auto";
    }
</script>

<Route>
    <header class="navbar">
        <section class="navbar-section">
            <img src="/images/icons/logo.svg" alt="readding" class="logo" />
            <a class="btn btn-link" href="/home" on:click={closeMenu}>
                <span class="brand">readding</span>
            </a>
        </section>
        <button class="btn btn-link navbar-toggle" on:click={toggleMenu}>
            ☰
        </button>
        <section class="navbar-section" class:open={isMenuOpen}>
            <a class="btn btn-link" href="/home" on:click={closeMenu}
                >Articles</a
            >
            <a class="btn btn-link" href="/share" on:click={closeMenu}>Create</a
            >
            <a class="btn btn-link" href="/settings" on:click={closeMenu}
                >Settings</a
            >
            <a
                href="https://links.eisenacher81.org/"
                target="_blank"
                class="btn btn-link"
                on:click={closeMenu}
            >
                Linkding
            </a>
        </section>
    </header>

    <Route path="/">
        <Home />
    </Route>
    <Route path="/home">
        <Home />
    </Route>
    <Route path="/settings">
        <Settings />
    </Route>
    <Route path="/share">
        <Share />
    </Route>
    <Route path="read/:id" let:meta>
        <Article id={meta.params.id} />
    </Route>
</Route>

<style>
    .logo {
        height: 40px;
        width: 40px;
        vertical-align: middle;
        /** text-align: center; **/
    }
    .brand {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
    }
    .navbar-toggle {
        display: none;
    }
    @media (max-width: 600px) {
        .navbar-toggle {
            display: block;
            background: none;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
        }
        .navbar-section:last-child {
            display: none;
        }
        .navbar-section:last-child.open {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background-color: #fff;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            z-index: 1000;
        }
        .navbar-section:last-child.open .btn-link {
            width: 100%;
            text-align: center;
            padding: 0.5rem 1rem;
        }
    }
    .navbar {
        position: relative;
    }
</style>
