<script>
    import { get, getMany } from "idb-keyval";
    import { createEventDispatcher } from "svelte";
    import { setLoading } from '../stores/loadingStore.js';

    export let id;
    
    export let readding_backend_token;

    let token;
    let server;

    const dispatch = createEventDispatcher();

    async function markRead() {
        setLoading(true);
        try {
            let update = {
                unread: false,
            };
            const response = await fetch(
                "https://readding.eisenacher81.org/api/bookmark/" + id,
                {
                    method: "PATCH",
                    headers: {
                        "Authorization": `Token ${readding_backend_token}`,
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(update),
                },
            );
            await response;
            dispatch("message", {
                success: true,
            });
        } catch (error) {
            console.error("Error marking as read:", error);
        } 
    }

    getMany(["server", "token"]).then(([srv, tok]) => {
        server = srv;
        token = tok;
    });
</script>

<button class="btn btn-link btn-sm" on:click={markRead}>Mark as read</button>

<style>
    .btn-link {
        color: #bcc3ce;
        padding: 0;
        height: auto;
        vertical-align: unset;
        border: none;
    }
</style>
