<script>
    export let repoId;
    export let repoUrl;

    import { Pulse } from "svelte-svg-spinners";
    import { marked } from "marked";
    import { configStore } from '../stores/configStore';
    import { onMount } from 'svelte';

    let loading = true;
    let loadingSummary = false;
    let showSummary = false;
    let repoData;
    let projectSummary;

    let readding_backend_token;

    onMount(async () => {
        await configStore.initConfig();
        readding_backend_token = $configStore.readding_backend_token;
    });

    async function getRepoData(id) {
        const res = await fetch("https://api.github.com/repos/" + id, {
            method: "GET",
            headers: {
                Accept: "application/vnd.github+json",
            },
        });

        return res.json();
    }

    async function getProjectSummary(url) {
        const res = await fetch(
            "https://readding.eisenacher81.org/api/apps/github/summarize?url=" +
                repoUrl,
            {
                method: "GET",
                headers: {
                    Accept: "text/x-markdown",
                    Authorization: `Bearer ${readding_backend_token}`,
                },
            },
        );

        return res.text();
    }

    $: if (readding_backend_token) {
        loading = true;
        getRepoData(repoId)
            .then((data) => {
                repoData = data;
                loading = false;
            })
            .catch(error => {
                console.error("Error fetching repo data:", error);
                loading = false;
            });
    }

    function loadProjectSummary() {
        if (!showSummary) {
            showSummary = true;
            loadingSummary = true;
            getProjectSummary(repoUrl)
                .then((data) => {
                    projectSummary = data;
                    loadingSummary = false;
                })
                .catch(error => {
                    console.error("Error fetching project summary:", error);
                    loadingSummary = false;
                });
        }
    }
</script>

{#if loading}
    <div class="flex-centered">
        <Pulse />
    </div>
{:else}
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">
                <img src="/images/icons/github.svg" alt="GitHub logo" class="avatar avatar-sm" />
                {repoData.name}
            </div>
            <div class="card-subtitle text-gray">
                <a target="_blank" href={repoData.html_url}
                    >{repoData.full_name}</a
                >
            </div>
        </div>
        <div class="card-body">{repoData.description}</div>
        <div class="card-footer">
            <span class="chip">
                <img src="/images/icons/star.svg" alt="Star icon" class="avatar avatar-sm" />
                Stars {repoData.stargazers_count}</span
            >
            <span class="chip">
                <img src="/images/icons/eye.svg" alt="Eye icon" class="avatar avatar-sm" />
                Watchers {repoData.watchers_count}</span
            >
            <span class="chip">
                <img
                    src="/images/icons/git-branch.svg"
                    alt="Git branch icon"
                    class="avatar avatar-sm"
                />
                Forks {repoData.forks_count}</span
            >
            <span class="chip">
                <img src="/images/icons/tool.svg" alt="Tool icon" class="avatar avatar-sm" />
                Open issues {repoData.open_issues_count}</span
            >
        </div>
        <div class="card-footer">
            <div class="float-right">
                <button class="btn btn-sm" on:click={loadProjectSummary} disabled={showSummary}>Project Summary</button>
            </div>
        </div>
    </div>
{/if}

{#if showSummary}
    <div class="comment_title">Project Summary</div>
    {#if loadingSummary}
        <div class="flex-centered">
            <Pulse />
        </div>
    {:else}
        <div class="content">
            {@html marked(projectSummary)}
        </div>
    {/if}
{/if}

<style>
    .comment_title {
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 1rem;
    }
</style>
