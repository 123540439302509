<script>
  export let url;
</script>

<style>
  .btn-link {
    color: #bcc3ce;
    padding: 0;
    height: auto;
    vertical-align: unset;
    border: none;
  }
</style>

<a class="btn btn-link btn-sm" href="{url}" target="_blank">Open original</a>