export const defaultFilterStates = {
    softwareFilter: false,
    videosFilter: false,
    shoppingFilter: false,
    foodFilter: false,
    travelFilter: false
};

export function setFilterStatesCookie(filterStates) {
    const jsonFilterStates = JSON.stringify(filterStates);
    document.cookie = `filterStates=${encodeURIComponent(jsonFilterStates)}; path=/; max-age=31536000`; // 1 year expiration
}

export function getFilterStatesCookie() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === 'filterStates') {
            try {
                return JSON.parse(decodeURIComponent(value));
            } catch (error) {
                console.error('Error parsing filterStates cookie:', error);
                return defaultFilterStates;
            }
        }
    }
    return defaultFilterStates;
}
