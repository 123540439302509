<script>
  import { configStore } from "../stores/configStore";
  import { createEventDispatcher } from "svelte";
  import { onMount } from "svelte";
  import { setLoading } from '../stores/loadingStore.js';

  export let id;

  let readding_backend_token;  

  let confirm = false;

  const dispatch = createEventDispatcher();

  async function remove() {
    setLoading(true);
    try {
      const response = await fetch("https://readding.eisenacher81.org/api/bookmark/" + id, {
        method: "DELETE",
        headers: {
            "Authorization": `Token ${readding_backend_token}`,
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
      });
      await response;
      confirm = false;
      dispatch("message", {success: true});
    } catch (error) {
      console.error("Error removing bookmark:", error);
    }
  }

  function doConfirm() {
    confirm = true;
  }

  function cancel() {
    confirm = false;
    dispatch("message", {success: false});
  }
  
  onMount(async () => {
      await configStore.initConfig();
      readding_backend_token = $configStore.readding_backend_token;
  });

</script>

<style>
  .btn-link {
    color: #bcc3ce;
    padding: 0;
    height: auto;
    vertical-align: unset;
    border: none;
  }
  .confirm {
    color: #ffb700!important;
    text-decoration: underline;
  }
</style>

{#if confirm}
  <button class="btn btn-link btn-sm confirm" on:click={cancel}>Cancel</button>
  <button class="btn btn-link btn-sm confirm" on:click={remove}>Confirm</button>
{:else}
  <button class="btn btn-link btn-sm" on:click={doConfirm}>Remove</button>
{/if}
