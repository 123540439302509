<script>
    export let hnId;

    import Time from "svelte-time";
    import { Pulse } from "svelte-svg-spinners";
    import { marked } from "marked";
    import { configStore } from '../stores/configStore';
    import { onMount } from 'svelte';
    let loading = true;
    let summaryLoading = false;
    let hnData;
    let hnCommentSummary = "";
    let showComments = false;

    let readding_backend_token;

    onMount(async () => {
        await configStore.initConfig();
        readding_backend_token = $configStore.readding_backend_token;
    });

    async function getHnData(id) {
        const res = await fetch(
            "https://readding.eisenacher81.org/api/apps/hn-article/" + id,
            {
                method: "GET",
                headers: {
                    Accept: "application/vnd.github+json",
                    Authorization: `Bearer ${readding_backend_token}`,
                },
            },
        );
        return res.json();
    }

    async function getHnCommentSummary(id) {
        const res = await fetch(
            "https://readding.eisenacher81.org/api/apps/hn-comments/" + id,
            {
                method: "GET",
                headers: {
                    Accept: "text/x-markdown",
                    Authorization: `Bearer ${readding_backend_token}`,
                },
            },
        );
        return res.text();
    }

    $: if (readding_backend_token) {
        loading = true;
        getHnData(hnId)
            .then(data => {
                hnData = data;
                loading = false;
            })
            .catch(error => {
                console.error("Error fetching HN data:", error);
                loading = false;
            });
    }

    function loadComments() {
        if (!showComments) {
            showComments = true;
            summaryLoading = true;
            getHnCommentSummary(hnId)
                .then(summary => {
                    hnCommentSummary = summary;
                    summaryLoading = false;
                })
                .catch(error => {
                    console.error("Error fetching HN comment summary:", error);
                    summaryLoading = false;
                });
        }
    }
</script>

{#if loading}
    <div class="flex-centered">
        <Pulse />
    </div>
{:else}
    <div class="card">
        <div class="card-header">
            {#if hnData.title}
            <div class="card-title h5">
                {hnData.title}
            </div>
            {/if}
            <div class="card-subtitle text-gray">
                Submitted by <a target="_blank" href={hnData.author_url}
                    >{hnData.author}</a
                >
                ({hnData.author_data.karma}) on <Time
                    format="YYYY/MM/DD"
                    timestamp={hnData.created_at}
                />
            </div>
        </div>
        <div class="card-body">{@html hnData.text}</div>
        <div class="card-footer">
            <span class="chip">Comments: {hnData.comment_count}</span>
            <span class="chip">Points: {hnData.points}</span>
            <div class="float-right">
                <button class="btn btn-sm" on:click={loadComments} disabled={showComments}>Comments Summary</button>
            </div>
        </div>
    </div>
{/if}

{#if showComments}
    <div class="comment_title">HN Comments</div>
    {#if summaryLoading}
        <div class="flex-centered">
            <Pulse />
        </div>
    {:else}
        <div class="content">
            {@html marked(hnCommentSummary)}
        </div>
    {/if}
{/if}

<style>
    .comment_title {
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 1rem;
    }
</style>
