<script>
    import { router } from "tinro";
    import { onMount } from "svelte";
    import { configStore } from "../stores/configStore";
    import Time from "svelte-time";
    import { Pulse } from "svelte-svg-spinners";

    import RemoveButton from "../components/RemoveButton.svelte";
    import MarkReadButton from "../components/MarkReadButton.svelte";
    import EditInLinkdingButton from "../components/EditInLinkdingButton.svelte";
    import OpenOriginalButton from "../components/OpenOriginalButton.svelte";

    import GitHubRepoCard from "../components/GitHubRepoCard.svelte";
    import HNPage from "../components/HNPage.svelte";
    import HnPage from "../components/HNPage.svelte";
    import YouTubePage from "../components/YouTubePage.svelte";
    import HnSays from "../components/HNSays.svelte";
    import AppShare from "../components/AppShare.svelte";

    export let id;

    let readding_backend_token;
    let bookmark;
    let result;

    let contentType = "article";

    let loading = true;

    async function getArticleDetails() {
        const response = await fetch(
            "https://readding.eisenacher81.org/api/bookmark/" + id + "/",
            {
                method: "GET",
                headers: {
                    Authorization: `Token ${readding_backend_token}`,
                },
            },
        );
        return response.json();
    }

    async function getArticleContent(url) {
        const response = await fetch(
            "https://readding.eisenacher81.org/api/article?url=" + url,
            {
                method: "GET",
                headers: {
                    Authorization: `Token ${readding_backend_token}`,
                },
            },
        );
        return response.json();
    }

    function goHome(event) {
        if (event.detail.success) {
            router.goto("/home");
        }
    }

    onMount(async () => {
        await configStore.initConfig();
        readding_backend_token = $configStore.readding_backend_token;

        getArticleDetails().then((bookmarkData) => {
            //console.log(bookmarkData);
            bookmark = bookmarkData;

            if (bookmark.url.match(/youtube\.com/)) {
                contentType = "youtube";
                const url = new URL(bookmark.url);
                bookmark.youtubeId = url.searchParams.get("v");
                loading = false;
            } else if (bookmark.url.match(/https:\/\/github.com\/.+\/.+$/)) {
                contentType = "github";
                const idRegex = /https:\/\/github.com\/(.+\/.+)$/i;
                var match = bookmark.url.match(idRegex);
                bookmark.repoId = match[1];
                loading = false;
            } else if (bookmark.url.match(/news\.ycombinator\.com/)) {
                contentType = "hn";
                bookmark.hnId = bookmark.url.match(/id=(\d+)/)[1];
                //console.log("This is a HN page with id : " + bookmark.hnId);
                loading = false;
            } else {
                getArticleContent(bookmarkData.url).then((bookmarkContent) => {
                    //console.log(bookmarkContent);
                    result = bookmarkContent;
                    loading = false;
                });
            }
        });
    });
</script>

<main>
    {#if bookmark}
        <div class="h4">
            {bookmark.title ? bookmark.title : bookmark.website_title}
        </div>
        {#if bookmark.url}
            <i class="text-tiny">{bookmark.url}</i>
        {/if}

        {#if bookmark.tag_names && bookmark.tag_names.length > 0}
            <div class="tags">
                {#each bookmark.tag_names as tag}
                    <span class="chip">{tag}</span>
                {/each}
            </div>
        {/if}

        {#if bookmark.description}
            <div class="desc">
                <p>{bookmark.description}</p>
            </div>
        {/if}
        <div class="actions">
            <span class="text-gray text-sm">
                <Time format="YYYY/MM/DD" timestamp={bookmark.date_added} />
            </span>
            <span class="text-gray text-sm">|</span>
            <HnSays {bookmark} {readding_backend_token} />
            <MarkReadButton on:message={goHome} {id} {readding_backend_token} />
            <span class="text-gray text-sm">|</span>
            <RemoveButton on:message={goHome} {id} />
            <span class="text-gray text-sm">|</span>
            <OpenOriginalButton url={bookmark.url} />
            <span class="text-gray text-sm">|</span>
            <AppShare bookmark="bookmark" />
            <EditInLinkdingButton {id} />
        </div>
    {/if}

    {#if contentType === "youtube"}
        <YouTubePage youTubeId={bookmark.youtubeId} />
    {:else if contentType === "github"}
        <GitHubRepoCard repoId={bookmark.repoId} repoUrl={bookmark.url} />
    {:else if contentType === "hn"}
        <HnPage hnId={bookmark.hnId} />
    {/if}

    {#if loading}
        <div class="flex-centered">
            <Pulse />
        </div>
    {:else if result}
        {#if result.content}
            <div class="content">
                {@html result.content}
            </div>
        {:else if result.article && result.article.article}
            <div class="content">
                {@html result.article.article}
            </div>
        {:else}
            <em>
                No content could be extracted... please go to the
                <a href={bookmark.url} target="_blank">original page</a>
                .
            </em>
        {/if}
    {/if}
</main>

<style>
    .actions {
        margin-bottom: 0.4rem;
    }

    .content {
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
    .chip {
        font-size: 0.8em;
        padding: 0.1em 0.4em;
        margin-right: 0.4em;
        margin-bottom: 0.2em;
        background-color: #fff8db;
        border-radius: 0.6em;
        color: #808d7c;
    }
    .desc {
        padding-top: 10px;
        font-size: 0.9em;
        font-style: lighter;
    }
</style>
