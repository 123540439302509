<script>
    export let url = "";

    $: icon = getIcon(url);

    function getIcon(url) {
        try {
            if (typeof url !== 'string') {
                throw new Error('URL must be a string');
            }

            if (url.startsWith("https://github.com")) {
                return "/images/icons/github.svg";
            } else if (url.includes("youtube.com")) {
                return "/images/icons/youtube.svg";
            } else if (url.startsWith("https://news.ycombinator.com")) {
                return "/images/icons/hn.svg";
            }
            return null;
        } catch (error) {
            console.error('Error in SiteIcon component:', error);
            return null;
        }
    }
</script>

{#if icon}
    <img src={icon} alt="Site Icon" class="site-icon" on:error={() => icon = null} />
{/if}

<style>
    .site-icon {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
    }
</style>
