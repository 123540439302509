<script>
    import { onMount } from "svelte";
    import Time from "svelte-time";
    import RemoveButton from "../components/RemoveButton.svelte";
    import MarkReadButton from "../components/MarkReadButton.svelte";
    import EditInLinkdingButton from "../components/EditInLinkdingButton.svelte";
    import BookmarkTitle from "../components/BookmarkTitle.svelte";
    import BookmarkSearch from "../components/BookmarkSearch.svelte";
    import BookmarkDescription from "../components/BookmarkDescription.svelte";
    import HnSays from "../components/HNSays.svelte";
    import { bookmarks } from "../stores/bookmarksStore";
    import { configStore } from "../stores/configStore";
    import {
        getFilterStatesCookie,
        setFilterStatesCookie,
    } from "../utils/cookieUtils";
    import { setLoading } from "../stores/loadingStore.js";

    let searchQuery = "";
    let softwareFilter = false;
    let videosFilter = false;
    let shoppingFilter = false;
    let foodFilter = false;
    let travelFilter = false;
    let filteredBookmarks = [];

    let readding_backend_token;

    function handleFilterChange(event) {
        const newFilterStates = {
            softwareFilter: event.detail.softwareFilter,
            videosFilter: event.detail.videosFilter,
            shoppingFilter: event.detail.shoppingFilter,
            foodFilter: event.detail.foodFilter,
            travelFilter: event.detail.travelFilter,
        };
        setFilterStatesCookie(newFilterStates);
    }

    function matchesSearch(bookmark) {
        return (
            bookmark.url.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (bookmark.title &&
                bookmark.title
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())) ||
            (bookmark.website_title &&
                bookmark.website_title
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())) ||
            (bookmark.website_description &&
                bookmark.website_description
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())) ||
            (bookmark.description &&
                bookmark.description
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()))
        );
    }

    function matchesFilters(bookmark) {
        const isSoftware =
            (bookmark.tag_names &&
                bookmark.tag_names.some((tag) =>
                    ["software", "freshmeat"].includes(tag.toLowerCase()),
                )) ||
            bookmark.url.toLowerCase().includes("github.com");
        const isVideo =
            bookmark.url.toLowerCase().includes("youtube.com") ||
            bookmark.url.toLowerCase().includes("vimeo.com");
        const isShopping =
            bookmark.tag_names &&
            bookmark.tag_names.some((tag) => tag.toLowerCase() === "shopping");
        const isFood =
            bookmark.tag_names &&
            bookmark.tag_names.some((tag) =>
                ["food", "recipe"].includes(tag.toLowerCase()),
            );
        const isTravel =
            bookmark.tag_names &&
            bookmark.tag_names.some((tag) => tag.toLowerCase() === "travel");

        return (
            (softwareFilter && isSoftware) ||
            (videosFilter && isVideo) ||
            (shoppingFilter && isShopping) ||
            (foodFilter && isFood) ||
            (travelFilter && isTravel)
        );
    }

    $: filteredBookmarks = $bookmarks
        ? $bookmarks.filter((bookmark) => {
              const matchesSearchCriteria = searchQuery
                  ? matchesSearch(bookmark)
                  : true;
              const matchesFilterCriteria =
                  softwareFilter ||
                  videosFilter ||
                  shoppingFilter ||
                  foodFilter ||
                  travelFilter
                      ? matchesFilters(bookmark)
                      : true;
              return (
                  matchesSearchCriteria &&
                  matchesFilterCriteria &&
                  bookmark.unread
              );
          })
        : [];

    function handleKeydown(event) {
        if (event.ctrlKey && event.key === "f") {
            event.preventDefault();
            document.getElementById("search-input").focus();
        }
    }

    async function refreshBookmarks() {
        try {
            setLoading(true);
            let url = `https://readding.eisenacher81.org/api/bookmarks`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Token ${readding_backend_token}`,
                },
            });

            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }

            let result = await res.json();

            console.log("Fetched bookmarks:", result);
            bookmarks.set(result.results);
        } catch (error) {
            console.error("Error fetching bookmarks:", error);
        } finally {
            setLoading(false);
        }
    }

    onMount(async () => {
        try {
            setLoading(true);
            await configStore.initConfig();
            configStore.subscribe((config) => {
                readding_backend_token = config.readding_backend_token;
            });

            const cookieFilterStates = getFilterStatesCookie();
            ({
                softwareFilter,
                videosFilter,
                shoppingFilter,
                foodFilter,
                travelFilter,
            } = cookieFilterStates);

            if (!readding_backend_token) {
                console.log("Configuration missing, not fetching bookmarks");
            } else {
                refreshBookmarks();
            }
        } catch (error) {
            console.error("Error in onMount:", error);
        }
    });
</script>

<svelte:window on:keydown={handleKeydown} />

<main>
    <BookmarkSearch
        bind:searchQuery
        bind:softwareFilter
        bind:videosFilter
        bind:shoppingFilter
        bind:foodFilter
        bind:travelFilter
        on:filterChange={handleFilterChange}
    />
    <ul class="bookmark-list">
        {#each filteredBookmarks as bookmark (bookmark.id)}
            <li>
                <BookmarkTitle {bookmark} />
                <BookmarkDescription {bookmark} />
                {#if bookmark.tag_names && bookmark.tag_names.length > 0}
                    <div class="tags">
                        {#each bookmark.tag_names as tag (tag)}
                            <span class="chip">{tag}</span>
                        {/each}
                    </div>
                {/if}
                <div class="actions">
                    <span class="text-gray text-sm">
                        <Time
                            format="YYYY/MM/DD"
                            timestamp={bookmark.date_added}
                        />
                    </span>
                    <span class="text-gray text-sm">|</span>
                    <HnSays {bookmark} {readding_backend_token} />
                    <MarkReadButton
                        on:message={refreshBookmarks}
                        id={bookmark.id}
                        {readding_backend_token}
                    />
                    <span class="text-gray text-sm">|</span>
                    <RemoveButton
                        on:message={refreshBookmarks}
                        id={bookmark.id}
                    />
                    <span class="text-gray text-sm">|</span>
                    <EditInLinkdingButton id={bookmark.id} />
                </div>
            </li>
        {/each}
    </ul>
</main>

<style>
    ul li {
        margin-top: 0.4rem;
    }
    ul.bookmark-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .chip {
        font-size: 0.8em;
        padding: 0.1em 0.4em;
        margin-right: 0.4em;
        margin-bottom: 0.2em;
        background-color: #fff8db;
        border-radius: 0.6em;
        color: #808d7c;
    }
</style>
