<script>
    export let bookmark;

    function truncate(str, n, useWordBoundary) {
        if (str.length <= n) {
            return str;
        }
        const subString = str.slice(0, n - 1);
        return (
            (useWordBoundary
                ? subString.slice(0, subString.lastIndexOf(" "))
                : subString) + " ... "
        );
    }

    $: description = bookmark.description || bookmark.website_description;
</script>

{#if description}
    <div class="description">
        <span class="text">
            {truncate(description, 160, true)}
        </span>
    </div>
{/if}

<style>
    .description {
        margin-top: 0.2rem;
        font-size: 0.9em;
        font-style: lighter;
    }
</style>
