import { writable } from 'svelte/store';
import { get, set } from 'idb-keyval';

function createConfigStore() {
    const { subscribe, set, update } = writable({
        readding_backend_token: ''
    });

    return {
        subscribe,
        setConfig: async (key, value) => {
            await set(key, value);
            update(config => ({ ...config, [key]: value }));
        },
        notifyConfigChanged: async () => {
            const readding_backend_token = await get('readding_backend_token') || '';
            set({ readding_backend_token });
        },
        initConfig: async () => {
            const server = await get('server') || '';
            const token = await get('token') || '';
            const readding_backend_token = await get('readding_backend_token') || '';
            set({ readding_backend_token });
        }
    };
}

export const configStore = createConfigStore();
