<script>
    import { onMount } from "svelte";
    import { inview } from 'svelte-inview';

    export let bookmark;

    export let readding_backend_token;
    
    let hnData = null;
    let isInView;
    const options = {};

    async function fetchHNData() {
        if (hnData) return;
        try {
            const response = await fetch(
                `https://readding.eisenacher81.org/api/apps/hn/hnsays?url=${encodeURIComponent(bookmark.url)}`,
                {
                    headers: {
                        Authorization: `Token ${readding_backend_token}`,
                    },
                },
            );
            const data = await response.json();
            if (data.hits && data.total_hits > 0) {
                hnData = data.hits.reduce((prev, current) =>
                    current.points > prev.points ||
                    (current.points === prev.points &&
                        current.comments > prev.comments)
                        ? current
                        : prev,
                );
            }
        } catch (error) {
            console.error("Error fetching HN data:", error);
        }
    }

</script>

<span use:inview={options} on:inview_change={(event) => {
      const { inView, entry, scrollDirection, observer, node} = event.detail;
      isInView = inView;
      if (isInView && readding_backend_token) {
        fetchHNData();
      }
    }}>
    &nbsp;
    {#if isInView && hnData}
        <a
            class="hn-link"
            href={hnData.hn_link}
            target="_blank">HN ({hnData.points} pts, {hnData.comments} cmt)</a>
        <span class="text-gray text-sm"> |&nbsp;</span> 
    {/if}    
</span>

<style>
    .hn-link {
        color: #e36414;
        text-decoration: none;
        font-size: 14px;
        line-height: inherit;
    }
    .hn-link:hover {
        text-decoration: underline;
    }
</style>
