<script>
    import { router } from "tinro";
    import { onMount } from "svelte";
    import { configStore } from "../stores/configStore";
    const urlParams = new URLSearchParams(window.location.search);
    import { setLoading } from '../stores/loadingStore.js';

    let readding_backend_token;

    let bookmark = {
        url: "",
        title: "",
        is_archived: false,
        unread: true,
        shared: false,
        tag_names: [],
    };

    let tags = {
        freshmeat: false,
        shopping: false,
        food: false,
        travel: false,
    };

    let json;
    let isSaving = false;
    let isDataLoading = false;

    onMount(async () => {
        await configStore.initConfig();
        readding_backend_token = $configStore.readding_backend_token;

        if (urlParams.get("url") || urlParams.get("text")) {
            console.log("Title shared: " + urlParams.get("title"));
            console.log("Text shared: " + urlParams.get("text"));
            console.log("URL shared: " + urlParams.get("url"));

            if (urlParams.get("url")) {
                bookmark.url = extractUrl(urlParams.get("url"));
            } else {
                bookmark.url = extractUrl(urlParams.get("text"));
            }
            if (urlParams.get("text"))
                bookmark.description = urlParams.get("text");
            if (urlParams.get("title")) bookmark.title = urlParams.get("title");

            bookmark.unread = true;

            if (bookmark.url) {
                getUrlData();
            }
        }
    });

    async function getUrlData() {
        isDataLoading = true;
        const response = await fetch(
            "https://readding.eisenacher81.org/api/article?format=short&url=" +
                bookmark.url,
            {
                method: "GET",
                headers: {
                    Authorization: `Token ${readding_backend_token}`,
                },
            },
        );
        if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
        }

        const data = await response.json();

        if (
            data.article.title &&
            data.article.title.length > bookmark.title.length
        )
            bookmark.title = data.article.title;

        //if (data.article.normal_url) bookmark.url = data.article.normal_url;

        if (data.article.excerpt) bookmark.description = data.article.excerpt;
        isDataLoading = false;
    }

    function extractUrl(inputString) {
        // Regular expression for matching URLs starting with http:// or https://
        const urlPattern = /(https?:\/\/[^\s]+)/;

        // Use match method to find the URL pattern in the string
        const match = inputString.match(urlPattern);

        // If a match is found, return it; otherwise, return null
        if (match) {
            return match[1]; // Return the captured URL
        } else {
            return null; // Or you could return "No valid URL found" as per your requirement
        }
    }

    function reset() {
        bookmark.title = "";
        bookmark.url = "";
        json = undefined;
    }

    async function doShare() {
        isSaving = true;
        setLoading(true);

        if (tags.shopping) bookmark.tag_names.push("shopping");
        if (tags.food) bookmark.tag_names.push("food");
        if (tags.travel) bookmark.tag_names.push("travel");

        try {
            const res = await fetch(
                "https://readding.eisenacher81.org/api/bookmark",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${readding_backend_token}`,
                    },
                    body: JSON.stringify(bookmark),
                },
            );

            json = await res.json();
            router.goto("/home", { replace: true });
        } catch (error) {
            console.error("Error sharing bookmark:", error);
        } finally {
            isSaving = false;
        }
    }
</script>

<main>
    <div class="container">
        <div class="columns">
            <div class="column col-12">
                {#if !json}
                    <form class="form-horizontal">
                        <div class="form-group">
                            <label class="form-label" for="title">Title</label>
                            <input
                                class="form-input"
                                type="text"
                                id="title"
                                placeholder="Title"
                                bind:value={bookmark.title}
                                disabled={isDataLoading}
                            />
                            {#if bookmark.description}
                                <i class="text-tiny">{bookmark.description}</i>
                            {/if}
                        </div>

                        <div class="form-group">
                            <label class="form-label" for="url">Url</label>
                            <input
                                class="form-input"
                                type="text"
                                id="url"
                                placeholder="Url"
                                bind:value={bookmark.url}
                                on:change={getUrlData}
                            />
                        </div>

                        <div class="form-group">
                            <label class="form-label" for="unread">Unread</label
                            >
                            <input
                                class="form-checkbox"
                                type="checkbox"
                                id="unread"
                                bind:checked={bookmark.unread}
                            />
                        </div>

                        <div class="form-group">
                            <label class="form-checkbox form-inline input-sm">
                                <input
                                    type="checkbox"
                                    bind:checked={tags.shopping}
                                /><i class="form-icon"></i> shopping
                            </label>
                            <label class="form-checkbox form-inline input-sm">
                                <input
                                    type="checkbox"
                                    bind:checked={tags.food}
                                /><i class="form-icon"></i> food
                            </label>
                            <label class="form-checkbox form-inline input-sm">
                                <input
                                    type="checkbox"
                                    bind:checked={tags.travel}
                                /><i class="form-icon"></i> travel
                            </label>
                        </div>

                        <div class="form-group">
                            <button
                                class="btn btn-primary save-button"
                                type="button"
                                on:click={doShare}
                                disabled={isSaving || isDataLoading}
                            >
                                {#if isSaving}
                                    <span class="loading"></span>
                                {:else}
                                    Save bookmark
                                {/if}
                            </button>
                        </div>
                    </form>
                {/if}
            </div>
        </div>
    </div>
</main>

<style>
    .save-button {
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .loading {
        display: inline-block;
        width: 1em;
        height: 1em;
        border: 2px solid #fff;
        border-radius: 50%;
        border-top-color: transparent;
        animation: spin 1s linear infinite;
    }
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
</style>
