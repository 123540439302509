<script>
    import SiteIcon from "./SiteIcon.svelte";

    export let bookmark;

    function truncateTitle(title, maxLength = 75) {
        if (title.length <= maxLength) return title;
        return title.slice(0, maxLength) + '...';
    }

    $: truncatedTitle = truncateTitle(bookmark.title || bookmark.website_title);
</script>

<div class="title">
    <div class="title-content">
        <SiteIcon url={bookmark.url} />
        <div class="title-and-tags">
            <a href="/read/{bookmark.id}" title={bookmark.title || bookmark.website_title}>
                {truncatedTitle}
            </a>
        </div>
    </div>
</div>

<style>
    .title {
        display: flex;
    }
    .title-content {
        display: flex;
    }
    .title-content :global(.site-icon) {
        margin-right: 5px;
        align-self: flex-start;
        margin-top: 0.4em;
    }
    .title-and-tags {
        display: flex;
        font-size: 0.9rem;
        flex-direction: column;
    }
    a,
    a:visited,
    a:hover,
    a:active {
        color: #5755d9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: inline-block;
    }
</style>
