<script>
    import { createEventDispatcher } from 'svelte';

    export let searchQuery = "";
    export let softwareFilter = false;
    export let videosFilter = false;
    export let shoppingFilter = false;
    export let foodFilter = false;
    export let travelFilter = false;

    const dispatch = createEventDispatcher();

    import { setFilterStatesCookie } from '../utils/cookieUtils';

    function toggleFilter(filter) {
        softwareFilter = filter === 'software' ? !softwareFilter : false;
        videosFilter = filter === 'videos' ? !videosFilter : false;
        shoppingFilter = filter === 'shopping' ? !shoppingFilter : false;
        foodFilter = filter === 'food' ? !foodFilter : false;
        travelFilter = filter === 'travel' ? !travelFilter : false;
    
        const newFilterStates = {
            softwareFilter,
            videosFilter,
            shoppingFilter,
            foodFilter,
            travelFilter
        };
    
        dispatch('filterChange', newFilterStates);
        setFilterStatesCookie(newFilterStates);
    }
</script>

<div class="filters">
    <span
        class="chip"
        class:active={softwareFilter}
        on:click={() => toggleFilter('software')}>Software</span
    >
    <span
        class="chip"
        class:active={videosFilter}
        on:click={() => toggleFilter('videos')}>Videos</span
    >
    <span
        class="chip"
        class:active={shoppingFilter}
        on:click={() => toggleFilter('shopping')}>Shopping</span
    >
    <span
        class="chip"
        class:active={foodFilter}
        on:click={() => toggleFilter('food')}>Food</span
    >
    <span
        class="chip"
        class:active={travelFilter}
        on:click={() => toggleFilter('travel')}>Travel</span
    >
</div>

<div class="search-container">
    <input
        type="text"
        id="search-input"
        bind:value={searchQuery}
        placeholder="Search bookmarks..."
    />
</div>

<style>
    .search-container {
        margin-bottom: 1rem;
    }
    #search-input {
        width: 100%;
        padding: 0.5rem;
        font-size: 0.5rem;
        border: 0.5px solid #ccc;
        border-radius: 4px;
    }
    .filters {
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
