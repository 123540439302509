<script>
  export let id;

</script>

<style>
  .btn-link {
    color: #bcc3ce;
    padding: 0;
    height: auto;
    vertical-align: unset;
    border: none;
  }
</style>

<a class="btn btn-link btn-sm" href="{'https://links.eisenacher81.org/bookmarks/' + id + '/edit'}" target="_blank">Edit in LinkDing</a>